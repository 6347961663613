import { TemplateSection } from "@/admin/lib/templateSection";
import {
  CaseDetails,
  EntityField,
  GroupedEntityFields,
  GroupedValueFields,
  ValueField
} from "@/api/caseApi";
import { PostCasePaymentAction } from "@/api/paymentApi";
import { ComputedRef, InjectionKey, Ref } from "vue";

export type CaseTemplateDetails = {
  id: string;
  templateId: number;
  header: TemplateSection;
  sections: TemplateSection[];
  footer: TemplateSection;
  valueFields: ValueField[];
  entityFields: EntityField[];
  logo: string;
  logoExtension: string;
  defaultHelpText: string;
  relatedTemplates: RelatedTemplate[];
};

export type RelatedTemplate = {
  id: number;
  templateId: number;
  description: string;
  name: string;
  price: number;
  referenceNumber: string;
  defaultHelpText: string;
  activeVersionId: number | null;
  allowBeingAddedMultipleTimes: boolean;
};

export type CaseTemplate = {
  kind: "Placeholder" | "Template" | "Upload";
  id: string;
  name?: string;
  price?: number;
  templateName?: string;
  referenceNumber?: string;
  customAppendixNumber?: string;
  templateId?: number;
  sortOrder: number;
};

export const CaseDetailsKey: InjectionKey<Ref<CaseDetails | undefined>> =
  Symbol();

export const CaseAppendicesKey: InjectionKey<ComputedRef<CaseTemplate[]>> =
  Symbol();

export const RelatedTemplatesKey: InjectionKey<ComputedRef<RelatedTemplate[]>> =
  Symbol();

export const SelectedTemplateKey: InjectionKey<
  Ref<CaseTemplateDetails | undefined>
> = Symbol();

export const ExclusiveEntityGroupsKey: InjectionKey<
  Ref<number[][] | undefined>
> = Symbol();

export const SetActiveHelpTextKey: InjectionKey<(id: number) => void> =
  Symbol();

export const EntityFieldsKey: InjectionKey<Ref<GroupedEntityFields>> = Symbol();

export const ValueFieldsKey: InjectionKey<Ref<GroupedValueFields>> = Symbol();

export const PostCasePaymentActionKey: InjectionKey<
  Ref<PostCasePaymentAction>
> = Symbol();

export const AddEntityFieldKey: InjectionKey<
  (entityField: EntityField) => void
> = Symbol();

export const AddValueFieldKey: InjectionKey<(valueField: ValueField) => void> =
  Symbol();

export const RenameAppendixKey: InjectionKey<(value: string) => void> =
  Symbol();

export const RenameAppendixWithIdKey: InjectionKey<
  (appendixId: string, value: string) => void
> = Symbol();

export const OverrideBaseTemplateSortOrderKey: InjectionKey<
  (sortOrder?: string | null) => void
> = Symbol();

export const PageCountKey: InjectionKey<Ref<number>> = Symbol();
