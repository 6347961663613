import { getBlobUrl } from "@/utils/apiHelpers";
import { ApiResponse, apiClient } from "./client";
import {
  CreatedTemplate,
  FilteredTemplates,
  SaveTemplateDto,
  PatchTemplateData,
  TemplateVersions,
  TemplateCategory,
  TemplateTag,
  TemplateMetadata,
  SparseTemplate,
  EntityField,
  TemplateVersionWithSections,
  UpdateTemplateVersion,
  CreateTemplateFromVersionDto,
  NodeDefinition,
  TemplateControlKind,
  CaseFieldValues,
  NewTemplateMeta,
  ListTemplateMetadata
} from "./types";

export const getTemplates =
  (ids?: number[], includeInactive = false) =>
  async (): Promise<ApiResponse<SparseTemplate[]>> => {
    const urlParams = new URLSearchParams({
      includeInactive: includeInactive.toString()
    });
    ids?.forEach(id => {
      urlParams.append("templateIds", id.toString());
    });
    const response = await apiClient.get(`templates?${urlParams.toString()}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as SparseTemplate[] };
    }
  };

export const getFilteredTemplates =
  (query: string) => async (): Promise<ApiResponse<FilteredTemplates>> => {
    const response = await apiClient.get(`/templates/filter?${query}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as FilteredTemplates };
    }
  };

export const saveTemplate =
  (data: SaveTemplateDto) =>
  async (): Promise<ApiResponse<CreatedTemplate>> => {
    const response = await apiClient.post(`templates`, { data });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as CreatedTemplate };
    }
  };

export const updateTemplateVersion =
  (data: UpdateTemplateVersion) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.patch(`templates/version`, { data });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const getTemplateMetadata =
  (templateId: number) =>
  async (): Promise<ApiResponse<ListTemplateMetadata>> => {
    const response = await apiClient.get(
      `/templates/${templateId}/templateMetadata`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as ListTemplateMetadata };
    }
  };

export const getTemplateVersionMetadata =
  (versionId: number) => async (): Promise<ApiResponse<TemplateMetadata>> => {
    const response = await apiClient.get(`/templates/${versionId}/metadata`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as TemplateMetadata };
    }
  };

export const getAllTemplateTags =
  () => async (): Promise<ApiResponse<TemplateTag[]>> => {
    const response = await apiClient.get("/templates/tags");
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as TemplateTag[] };
    }
  };

export const getAllTemplateCategories =
  () => async (): Promise<ApiResponse<TemplateCategory[]>> => {
    const response = await apiClient.get("/templates/categories");
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as TemplateCategory[] };
    }
  };

export const getNewTemplateMeta =
  () => async (): Promise<ApiResponse<NewTemplateMeta>> => {
    const response = await apiClient.get("/templates/newTemplateMeta");
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as NewTemplateMeta };
    }
  };

export const getTemplateVersions =
  (query: string) => async (): Promise<ApiResponse<TemplateVersions>> => {
    const response = await apiClient.get(`/templates/versions?${query}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as TemplateVersions };
    }
  };

export const patchTemplate =
  (id: number, data: PatchTemplateData) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.patch(`templates/${id}`, { data });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const getEntityFields =
  () => async (): Promise<ApiResponse<EntityField[]>> => {
    const response = await apiClient.get("templates/entities");
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as EntityField[] };
    }
  };

export const duplicateTemplateVersion =
  (id: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.get(`templates/${id}/duplicate`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const getTemplateVersion =
  (id: number) =>
  async (): Promise<ApiResponse<TemplateVersionWithSections>> => {
    const response = await apiClient.get(`templates/version/${id}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as TemplateVersionWithSections };
    }
  };

export const createTemplateFromVersion =
  (data: CreateTemplateFromVersionDto) =>
  async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.post(`templates/new`, {
      data: data
    });
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export const getTemplateVersionPdfLink =
  (templateId: number, versionId: number) =>
  async (): Promise<ApiResponse<string>> => {
    const response = await apiClient.get(
      `/templates/${templateId}/versions/${versionId}/pdf-link`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return {
        result: response.data as string
      };
    }
  };

export const getTemplatePreview =
  (version: { templateId: number; id: number }) =>
  async (): Promise<ApiResponse<null>> => {
    const newTab = window.open("", "_blank");
    newTab?.document.write("Skapar pdf . . . ");

    const response = await apiClient.get(
      `templates/${version.templateId}/versions/${version.id}/pdf`,
      {
        responseType: "blob"
      }
    );
    if (response.hasError) {
      newTab?.close();
      return { error: response.error };
    } else {
      if (newTab) newTab.location.href = getBlobUrl(response);

      return {
        result: null
      };
    }
  };

export const getRelatedTemplates =
  (templateId: number) => async (): Promise<ApiResponse<SparseTemplate[]>> => {
    const response = await apiClient.get(
      `templates/relatedTemplates/${templateId}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as SparseTemplate[] };
    }
  };

export const getCaseTemplate =
  (templateId: number) =>
  async (): Promise<ApiResponse<CaseDetailTemplate>> => {
    const response = await apiClient.get(
      `templates/caseTemplate/${templateId}`
    );
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: response.data as CaseDetailTemplate };
    }
  };

export const deleteTemplate =
  (templateId: number) => async (): Promise<ApiResponse<null>> => {
    const response = await apiClient.delete(`templates/delete/${templateId}`);
    if (response.hasError) {
      return { error: response.error };
    } else {
      return { result: null };
    }
  };

export type CaseDetailTemplate = {
  id: string;
  kind: "Template" | "Placeholder" | "Upload";
  name: string;
  price?: number;
  referenceNumber: string;
  defaultHelpText: string;
  sortOrder: number;
  templateId: number;
  version: number;
  header: NodeDefinition;
  sections: NodeDefinition[];
  footer: NodeDefinition;
  valueFields: ValueField[];
  relatedTemplates: SparseTemplate[];
};

export type ValueField = Field & {
  name: string;
};

export type Field = {
  readOnly: boolean;
  values: CaseFieldValues;
  valueKind: TemplateControlKind;
};
